<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="article">
            <p style="text-align: center;">
                <span style="font-size: 20px; text-decoration: none;"><strong>充值服务协议</strong></span>
            </p>
            <p>
                <br/>
            </p>
            <p>
                万恒云按照本协议的规定及其不时发布的操作规则提供基于互联网的太阳充值服务(以下称&quot;充值服务&quot;)，为获得充值服务，服务使用人(以下称&quot;用户&quot;)同意本协议的全部条款并按照页面上的提示完成全部的充值程序。用户在进行充值程序过程中点击“同意”按钮即表示用户完全接受本协议项下的全部条款。用户在使用万恒云提供的各项服务之前，应仔细阅读本服务协议，如用户不同意本充值用户协议及/或随时对其的修改，请停止使用万恒云提供的服务。
            </p>
            <p>
                特别提示:未成年用户使用万恒云充值服务，必须得到家长或其他监护人的同意。万恒云对于未成年用户使用本服务过程中的行为，以及因其使用本服务而产生的一切后果不承担任何责任。
            </p>
            <p>
                1、关于太阳
            </p>
            <p>
                太阳是万恒云充值平台的中间货币，可以用于服务，如:购买会员、购买大师预测、打赏他人、包月大师等，太阳不能兑换成人民币。
            </p>
            <p>
                2、服务内容
            </p>
            <p>
                万恒云的充值系统提供支付宝、微信、银联等不同的充值方式。用户使用该平台进行充值，可以为大公鸡账号充值太阳
            </p>
            <p>
                3、操作方法
            </p>
            <p>
                3.1用户可以在此充值系统上自由选择具体的充值方式，并按相应页面提示的程序完成充值。
            </p>
            <p>
                3.2使用万恒云充值方式充值成功后有5分钟的调整时间，用户请在充值成功的5分钟后尝试重新进入大公鸡七星彩
            </p>
            <p>
                3.3用户使用各种充值方式进行充值后，应保留充值订单号作为今后发生问题核对依据凭证(用户就充值服务投诉，但未有任何充值卡或其他有效凭证佐证的，万恒云将不会作出任何补偿或赔偿)
            </p>
            <p>
                4、风险提示
            </p>
            <p>
                4.1在使用充值方式时，用户务必仔细确认自己的帐号并仔细选择相关操作选项。若因为用户自身输入账号错误、操作不当或不了解充值计费方式等因素造成充错帐号、错选充值种类等情形而损害自身权益的，万恒云将不会作任何补偿或赔偿。
            </p>
            <p>
                4.2若用户以非法的方式，或使用非万恒云所指定的充值方式进行充值，万恒云不保证该充值顺利或者正确完成。若因此造成用户权益受损时，万恒云不会作任何补偿或赔偿，万恒云同时保留随时终止该用户大公鸡七星彩账号资格及使用各项充值服务的权利。
            </p>
            <p>
                4.3用户不得通过万恒云充值进行任何违法犯罪活动，否则万恒云有权终止服务，情节严重的，依法移交司法机关。
            </p>
            <p>
                5、充值成功后
            </p>
            <p>
                充值成功后，充值所增加的账号内太阳数可由用户自由使用，但万恒云不会提供任何退还或逆向兑换服务。
            </p>
            <p>
                6、渠道商服务手续费用
            </p>
            <p>
                各充值方式对应的渠道商可能会按其标准制定相关充值渠道手续费用(可能会在充值操作页面或渠道商其他页面上提示)，并在用户充值时收取。万恒云特此提醒:请用户务必注意各充值方式的渠道商服务手续费，并按自己的需求选取充值方式。
            </p>
            <p>
                7、若因万恒云充值方式原因造成用户充值失实并经查证属实的，万恒云将根据用户充值情况作出变动措施
            </p>
            <p>
                7.1因充值方式原因，造成系统充值额小于用户实际充值额的，万恒云予以补其差额:
            </p>
            <p>
                7.2因充值方式原因，造成系统充值额大于用户实际充值额的，万恒云有权追回差额:
            </p>
            <p>
                7.3因渠道商收取服务手续费用等原因造成系统充值额小于用户实际充值额的，不属于用户充值失实。
            </p>
            <p>
                8、服务变更，中新或终止
            </p>
            <p>
                8.1万恒云可以根据业务需求随时变更服务或更新本协议的条款，但应在相关的页面上及时提示修改内容。修改后的服务协议一旦在页面上公布即代替原来的服务协议。
            </p>
            <p>
                8.2如因系统维护或升级的需要而需暂停网络充值服务，万恒云将尽可能事先进行通告。
            </p>
            <p>
                8.3如发生下列任何一种情形，万恒云有权随时中断或终止向用户提供本协议项下的网络服务而无需通知用户
            </p>
            <p>
                (a)用户提供的个人资料不真实:
            </p>
            <p>
                (b)用户违反本协议中规定的使用规则。
            </p>
            <p>
                8.4除前款所述情形外，万恒云同时保留在不事先通知用户的情况下随时中断或终止部分或全部网络充值服务的权利，对于充值服务的中断或终止而造成的任何损失，万恒云无需对用户或任何第三方承担任何责任。
            </p>
            <p>
                9、免责声明和损害赔偿
            </p>
            <p>
                9.1发生下列情况时，万恒云免于承担任何法律责任:
            </p>
            <p>
                (a)用户因使用第三方支付渠道充值过程中发生的相关争议;
            </p>
            <p>
                (b)由于用户将密码告知他人导致的用户财产损失;
            </p>
            <p>
                (c)因用户个人故意或重大过失，或本协议之外的第三方所造成的用户财产损失了
            </p>
            <p>
                (d)由不可抗力及不可预见的情势导致的各种情况和纠纷:不可抗力和不可预见情势包括;黑客攻击、电信部门技术调整导致重大影响，政府管制导致的暂时关闭、病毒侵袭。
            </p>
            <p>
                9.2用户同意保障和维护万恒云及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给万恒云或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。
            </p>
            <p>
                10、其他
            </p>
            <p>
                10.1本协议的订立、执行和解释及争议的解决均应适用中国法律。
            </p>
            <p>
                10.2如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决:协商不成时，任何一方均应向广州市番禺区人民法院提起诉讼。
            </p>
            <p>
                10.3本协议项下所有的通知均可通过重要页面公告、电子邮件或常规的信件传送等方式进行;该等通知于发送之日视为已送达收件人。
            </p>
            <p>
                <br/>
            </p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";

export default {
    data(){
        return{
            title:this.$route.meta.title
        }
    },
    components:{
        vHeader
    },
    created(){
    }
}
</script>
<style lang="less" scoped>
    .container{
        .article{
            padding: 0 10px;
            font-size: 16px;
            h2{
                text-align: center;
            }
        }
    }
</style>